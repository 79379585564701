<!--
 * @version: 1.0
 * @Date: 2023-05-17 13:45:50
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="date-time-wrapper design-form-style">
    <div class="flex-layout">
      <div class="label">
        <span class="required-icon" v-if="required">*</span>
        <span>{{ title }}</span>
      </div>
      <div class="placeholder">
        {{ placeholder || '请选择地点' }}
        <RightOutlined style="color: rgba(0, 0, 0, 0.25); font-size: 14px" />
      </div>
    </div>
    <div :class="{'tips': true, 'tips-label': required }" v-if="tipsText">{{ tipsText }}</div>
  </div>
</template>

<script setup>
import { RightOutlined } from '@ant-design/icons-vue'
defineProps(['title', 'placeholder', 'tipsText', 'required'])
</script>

<style lang='less' scoped>
.flex-layout {
  justify-content: space-between;
}
.required-icon{
  color:#c3161c !important;
}
</style>